import type { User } from '~/types/user'

export const useUserStore = defineStore('userStore', () => {
  const user = ref(
    JSON.parse(localStorage.getItem('user') || '{}') as User | null,
  )

  const isLoggedIn = computed((): boolean => {
    return useCookie('lk_token').value !== null
  })

  return {
    user,
    isLoggedIn,
  }
})
